/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

:root {
    --amplify-primary-color: #11a29b;
    --amplify-primary-tint: #3f6060;
    --amplify-primary-shade: #2dba89;
    --amplify-background-color: #e0faf4;
  }
/* 
  .rotate {
    animation: rotation 2s infinite linear;
    opacity: 0.8;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  } */


.u-loading {
    width: 128px;
    height: 128px;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
.u-loading__symbol {
    background-color: none;
    padding: 8px;
    animation: loading 3s infinite;
    border-radius: 5px;
}
.u-loading__symbol img {
    display: block;
    max-width: 100%;
    animation: loading-icon 3s infinite;
}
@keyframes loading {
    0% {
        transform: perspective(250px) rotateX(0deg) rotateY(0deg);
   }
    15% {
        background-color: none;
   }
    16% {
        background-color: none;
   }
    50% {
        transform: perspective(250px) rotateX(180deg) rotateY(0deg);
        background-color: none;
   }
    65% {
        background-color: none;
   }
    66% {
        background-color: none;
   }
    100% {
        transform: perspective(250px) rotateX(180deg) rotateY(-180deg);
   }
}
@keyframes loading-icon {
    0% {
        transform: perspective(250px) rotateX(0deg) rotateY(0deg);
   }
    15% {
        transform: perspective(250px) rotateX(0deg) rotateY(0deg);
   }
    16% {
        transform: perspective(250px) rotateX(180deg) rotateY(0deg);
   }
    50% {
        transform: perspective(250px) rotateX(180deg) rotateY(0deg);
   }
    65% {
        transform: perspective(250px) rotateX(180deg) rotateY(0deg);
   }
    66% {
        transform: perspective(250px) rotateX(180deg) rotateY(180deg);
   }
    100% {
        transform: perspective(250px) rotateX(180deg) rotateY(180deg);
   }
}
